import React, { useState, useReducer, useEffect } from "react";

import "./App.scss";
import { FaPencilAlt } from "react-icons/fa";
import { RxFramerLogo } from "react-icons/rx";
import InstaCard from "./Components/InstaCard.jsx";
import YoutubeCard from "./Components/YoutubeCard";
import TiktokCard from "./Components/TiktokCard.js";
import Topbar from "./Components/Topbar";
import { css } from "@emotion/react";
import { PropagateLoader } from "react-spinners";
import Footer from "./Components/Footer";
import DownloadPopup from "./Components/DownloadPopup";
import Accordianbox from "./Components/Accordianbox.jsx";
import AboutSection from "./Components/AboutSection.jsx";
import axios from "axios";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Loader = () => {
  return (
    <div className="loader">
      <PropagateLoader color={"#e7004d"} css={override} />
    </div>
  );
};

function App() {
  // console.log("process.env.REACT_APP_apiKey",process.env.REACT_APP_apiKey)
  //const HOST = 'http://localhost:5001'
  const HOST = "http://143.198.58.92:5001";

  const [videoURL, setVideoURL] = useState("");
  // console.log("🚀 ~ videoURL:", videoURL)
  const [videoData, setVideoData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  // console.log("error>>>>..", error);
  const [getVideoSubsError, setGetVideoSubsError] = useState("");
  // console.log("🚀 ~ App ~ getVideoSubsError:", getVideoSubsError);

  const [activeImage, setActiveImage] = useState([
    "tiktok",
    "youtube",
    "instagram",
  ]);
  // console.log("🚀 ~ App ~ activeImage:", activeImage);




  const [ip, setIp] = useState("");
  // console.log("🚀 ~ ip:", ip)

  useEffect(() => {
  const fetchData = async () => {
    try {
      const ipResponse = await axios.get("https://api.ipify.org?format=json");
      const ipData = ipResponse.data;
      setIp(ipData?.ip);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  fetchData()
}, [])




  const handleFetchVideoData = async () => {
    setLoading(true);
    setError("");
    setGetVideoSubsError("");
    try {
      let apiUrl = "";
      if (videoURL.includes("instagram.com")) {
        apiUrl = `https://instagramserver.tokbackup.com/get_single_media_info_from_url?media_id=${encodeURIComponent(
          videoURL
        )}&ip=${ip}`;
        setActiveImage("instagram");
      } else if (
        videoURL.includes("youtube.com") ||
        videoURL.includes("youtu.be")
      ) {
        apiUrl = `https://yt.tokbackup.com/get_video_transcripts?url=${encodeURIComponent(
          videoURL
        )}&ip=${ip}`;
        setActiveImage("youtube");
      } else if (videoURL.includes("tiktok.com")) {
        apiUrl = `https://scriptadmin.toktools.online/v1/tiktok/fetchTikTokData?video=${videoURL}&get_transcript=true&ip=${ip}`;
        // apiUrl = `https://script.tokaudit.io/api/tiktok/subtitles?link=${encodeURIComponent(videoURL)}`;
        setActiveImage("tiktok");
      } else {
        throw new Error("Unsupported URL1");
      }

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "cross-origin-resource-policy": "cross-origin",
          // "x-api-key": "Toktools2024@!NowMust",
          "x-api-key": process.env.REACT_APP_apiKey,
          "Referer": document.referrer,
          "User-Agent": navigator.userAgent,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch video data");
      }
      const data = await response.json();
      // console.log("🚀 ~ data>>>>>>>>>>>>..:", data);
      // console.log("🚀 ~ data.error>>>>>>>>>>>>..:", data?.error);
      if (data?.error) {
        // console.log("caaaaaaaaaakca");
        setError(data?.error);
      } else {
        setVideoData(data);
      }
    } catch (error) {
      setError(error.message);
      setVideoData();
    } finally {
      setLoading(false);
    }
  };

  // const CheckIfTiktokLinkIsValid = () => {
  //   if (!videoURL) return false;
  //   try {
  //     var id = videoURL?.match(/https?:\/\/(www\.)?instagram\.com\//);

  //     if (id) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } catch (e) {
  //     console.log(e.message);
  //     return false;
  //   }
  // };

  const validateURL = (url) => {
    // console.log("🚀 ~ validateURL ~ url:", url)
    // const tiktokRegex =
    //   /(?:https?:\/\/)?(?:www\.)?tiktok\.com\/.*(?:video|@.*?\/video)\/.*$/;
    //   const instagramRegex = /(?:https?:\/\/)?(?:www\.)?instagram\.com\/reels\/[\w-]+\/?/;
    //   const youtubeRegex =
    //   /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})$/;

    if (url.includes("tiktok")) {
      return "tiktok";
    } else if (url.includes("instagram")) {
      return "instagram";
    } else if (url.includes("youtube")) {
      return "youtube";
    } else {
      return null; // Unsupported platform
    }
  };

  return (
    <div className="App ">
      <Topbar />
      <div className=" bg-gradient">
        <div class="sm:pt-[75px] pt-10">
          {/* <img
            src="../fav.png"
            alt="logo"
            style={{
              fontSize: "75px",
              color: "#ccdae7",
              margin: "auto",
              width: "150px",
            }}
          /> */}
        </div>
        <h1
          style={{
            color: "rgb(204, 218, 231)",
            // width: "90%",
            maxWidth: "1000px",
            margin: "auto",
            marginTop: "30px",
            marginBottom: "-20px",
            fontFamily: "DM Sans",
          }}
          className="sm:text-[52px] text-4xl font-bold  sm:leading-normal leading-tight sm:w-[90%] w-[98%]"
        >
          <i>
            TikTok{" "}
            <span
              className="text-gradient__indigo-red px-0"
              style={{ fontWeight: "1000" }}
            >
              {" "}
              Transcript{" "}
            </span>{" "}
            Generator{" "}
          </i>
        </h1>

        <h5
          className="sm:text-md sm:text-base text-sm font-medium leading-normal mt-7 sm:w-full w-[95%]"
          style={{ color: "rgb(204, 218, 231)" }}
        >
          <i>Turn speech into text for any TikTok, Reels, and Shorts video</i>
        </h5>

        <div className="flex items-center gap-2 justify-center sm:mt-20 mt-12">
          <div>
            <img
              src="Images/tiktokcircle.png"
              className={`sm:w-[60px] w-[55px] rounded-[50%] opacity-20 ${
                activeImage.includes("tiktok")
                  ? "border-2 border-blue-500 opacity-100"
                  : ""
              }`}
            />
          </div>
          <div>
            <img
              src="Images/youtubecircle.png"
              className={`sm:w-[60px] w-[55px] rounded-[50%] opacity-20 ${
                activeImage.includes("youtube")
                  ? "border-2 border-blue-500 opacity-100"
                  : ""
              }`}
            />
          </div>
          <div>
            <img
              src="Images/instagramcircle.png"
              className={`sm:w-[60px] w-[55px] rounded-[50%] opacity-20 ${
                activeImage.includes("instagram")
                  ? "border-2 border-blue-500 opacity-100"
                  : ""
              }`}
            />
          </div>
        </div>
        <div
          className="flex justify-center mt-4"
          style={{ marginBottom: "-15px" }}
        >
          <div
            className="block py-6 sm:px-6 p-0 rounded-lg  "
            style={{ width: "90%", maxWidth: "720px" }}
          >
            <div
              className="mb-3 flex items-center mx-auto"
              style={{ maxWidth: "500px" }}
            >
              <div class="p-1 w-full rounded-full bg-gradient-to-r from-[#152ce2] via-[#a205c3] to-[#e7004d] md:mr-2">
                <div class="flex flex-col sm:flex-row items-center font-semibold bg-gray-800 rounded-full w-full">
                  <div class="flex w-full items-center">
                    <input
                      type="text"
                      className="block w-full px-10 py-4 text-base font-medium text-gray-900 shadow bg-white bg-clip-padding rounded-3xl transition ease-in-out m-0"
                      // placeholder={placeholderText}
                      placeholder="Enter Video Url"
                      value={videoURL}
                      style={{
                        border: "none",
                        outline: "none",
                        borderRadius: "50px",
                      }}
                      onChange={(e) => setVideoURL(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <button
                type="button"
                className=" 
            inline-block px-6 py-2.5 bg-blue-600 text-white  
            font-medium text-xs leading-tight uppercase rounded-3xl 
            shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 
            focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 
            active:shadow-lg transition duration-150 ease-in-out  -ml-32
            
             bg-gradient-to-r from-[#152ce2] via-[#a205c3] to-[#e7004d] whitespace-nowrap
            "
                style={{
                  opacity: loading ? 0.5 : 1,
                  pointerEvents: loading ? "none" : "auto",
                  marginLeft: "-110px",
                  backgroundColor: "rgb(221, 175, 36)",
                }}
                // onClick={() => {
                //   if (!validateURL()) {
                //     setGetVideoSubsError(
                //       "Please enter a valid instagram video url"
                //     );
                //     return;
                //   }
                //   console.log("valid instagram url !");
                //   handleFetchVideoData();
                // }}

                onClick={() => {
                  const platform = validateURL(videoURL);
                  if (!platform) {
                    setError("Unsupported URL....");
                    return;
                  }
                  setActiveImage(platform);
                  handleFetchVideoData();
                }}
              >
                START
              </button>
            </div>

            {loading && (
              <>
                <h2
                  style={{
                    marginTop: "100px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {/* Loading Data... */}
                  <Loader />
                </h2>
                <h3
                  className="animate-pulse"
                  style={{
                    color: "white",
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Getting Video Info....
                </h3>
              </>
            )}
            {error && (
              <div
                className="text-white text-xs mt-4 bg-red-500 rounded-2xl px-4 py-1"
                style={{ width: "fit-content", margin: "auto", color: "white" }}
              >
                <p style={{ color: "white" }}>{error}</p>
              </div>
            )}

            {(!loading && error) ||
              (getVideoSubsError && (
                <div
                  className="text-white text-xs mt-4 bg-red-500 rounded-2xl px-4 py-1"
                  style={{
                    width: "fit-content",
                    margin: "auto",
                    color: "white",
                  }}
                >
                  <p style={{ color: "white" }}>{error || getVideoSubsError}</p>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="flex justify-center my-12 mb-2">
        <div style={{ marginTop: "-250px", marginBottom: "80px" }}>
          {/* {!loading && videoData && !videoData?.transcript && !videoData?.subtitles && videoData?.subtitles_text && !getVideoSubsError ? ( */}
          {!loading && videoData && videoURL.includes("instagram.com")&& !videoData?.transcript && !videoData?.subtitles && !getVideoSubsError ? (
            //  instagram
            <InstaCard data={videoData} loading={loading} videoURL={videoURL} />
          ) : // ) : !loading && videoData && videoData?.subtitles !== false && !videoData?.transcript ? (
          // !loading && videoData && videoURL.includes("tiktok.com") && videoData?.subtitles !== false && !videoData?.transcript ? (
            !loading && videoData && videoURL.includes("tiktok.com") && !videoData?.transcript ? (
            <TiktokCard
              data={videoData}
              loading={loading}
              videoURL={videoURL}
            />
          ) : // ) : !loading && videoData && videoData?.transcript?.length > 0 && !videoData?.subtitles && !getVideoSubsError && videoData?.video_id ? (
          !loading &&
           ( (videoURL.includes("youtube.com") || videoURL.includes("youtu.be")) ) && videoData && videoData?.transcript?.length > 0 && !videoData?.subtitles && !getVideoSubsError && videoData?.video_id ? (
            <YoutubeCard
              data={videoData}
              loading={loading}
              videoURL={videoURL}
              // successMessage={successMessage}
              // getVideoSubsError={error}
            />
          ) : null}
        </div>
      </div>

      <AboutSection />

      <Accordianbox />

      <Footer />
    </div>
  );
}

export default App;
